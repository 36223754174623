@import '../../../../scss/theme-bootstrap';

.site-footer__connect {
  .refer-friend {
    &__wrapper {
      span {
        padding-top: 13px;
        text-transform: uppercase;
        font-weight: 700;
        display: block;
        font-size: 18px;
      }
    }
  }
}

.refer-friend {
  &__offers a,
  &__home a {
    @include button();
  }
  &__landing + &__wrapper {
    text-align: center;
    @include breakpoint($portrait-up) {
      position: absolute;
      right: 5%;
      width: 60%;
      text-align: center;
      bottom: 22%;
      z-index: 99;
    }
    a {
      padding: 9px 19px 8px 19px;
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      text-align: center;
      font-size: 15px;
      line-height: 16px;
      background: $white;
      color: $color-off-black;
      border: 1px solid $color-off-black;
      &:hover {
        cursor: pointer;
        background: $color-off-black;
        color: $white;
        text-decoration: none;
      }
    }
  }
  &__landing {
    .content-block-large__cta-wrap {
      display: none;
    }
  }
}

.site-header__nav {
  .refer-friend {
    &__wrapper {
      padding: 7px 11px 3px;
      span {
        font-size: 17px;
        text-transform: uppercase;
      }
    }
  }
}
.content-block {
  &__refer-friend {
    &--text-one {
      font-size: 28px;
      @include breakpoint($medium-up) {
        font-size: 32px;
      }
    }
    &--text-two {
      font-size: 18px;
      @include breakpoint($medium-up) {
        font-size: 22px;
      }
    }
  }
}
